import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import React, { ReactElement, useState } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '../lib/client-utils';
import styles from '../styles/Home.module.css';

interface TabsProps {
  children: ReactElement[];
  selectedIndex?: number;
  onTabSelected?: (index: number) => void;
}

function Tabs(props: TabsProps) {
  const activeIndex = props.selectedIndex ?? 0;
  if (!props.children) {
    return <></>;
  }

  let tabs = React.Children.map(props.children, (child, index) => {
    return (
      <button
        className="lk-button"
        onClick={() => {
          if (props.onTabSelected) props.onTabSelected(index);
        }}
        aria-pressed={activeIndex === index}
      >
        {child?.props.label}
      </button>
    );
  });
  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabSelect}>{tabs}</div>
      {props.children[activeIndex]}
    </div>
  );
}

function DemoMeetingTab({ label }: { label: string }) {
  const router = useRouter();
  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));
  const startMeeting = () => {
    if (e2ee) {
      router.push(`/rooms/${generateRoomId()}#${encodePassphrase(sharedPassphrase)}`);
    } else {
      router.push(`/rooms/${generateRoomId()}`);
    }
  };
  return (
    <div className={styles.tabContent}>
      <p style={{ margin: 0 }}> Meeting</p>
      <button style={{ marginTop: '1rem' }} className="lk-button" onClick={startMeeting}>
        Start Meeting
      </button>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <input
            id="use-e2ee"
            type="checkbox"
            checked={e2ee}
            onChange={(ev) => setE2ee(ev.target.checked)}
          ></input>
          <label htmlFor="use-e2ee">Enable end-to-end encryption</label>
        </div>
        {e2ee && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <label htmlFor="passphrase">Passphrase</label>
            <input
              id="passphrase"
              type="password"
              value={sharedPassphrase}
              onChange={(ev) => setSharedPassphrase(ev.target.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function CustomConnectionTab({ label }: { label: string }) {
  const router = useRouter();

  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const serverUrl = formData.get('serverUrl');
    const token = formData.get('token');
    if (e2ee) {
      router.push(
        `/custom/?liveKitUrl=${serverUrl}&token=${token}#${encodePassphrase(sharedPassphrase)}`,
      );
    } else {
      router.push(`/custom/?liveKitUrl=${serverUrl}&token=${token}`);
    }
  };
  return (
    <p>v2.4.0</p>
    // <form className={styles.tabContent} onSubmit={onSubmit}>
    //   {/* <p style={{ marginTop: 0 }}>
    //     Connect LiveKit Meet with a custom server using LiveKit Cloud or LiveKit Server.
    //   </p>
    //   <input
    //     id="serverUrl"
    //     name="serverUrl"
    //     type="url"
    //     placeholder="LiveKit Server URL: wss://*.livekit.cloud"
    //     required
    //   />
    //   <textarea
    //     id="token"
    //     name="token"
    //     placeholder="Token"
    //     required
    //     rows={5}
    //     style={{ padding: '1px 2px', fontSize: 'inherit', lineHeight: 'inherit' }}
    //   />
    //   <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
    //     <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
    //       <input
    //         id="use-e2ee"
    //         type="checkbox"
    //         checked={e2ee}
    //         onChange={(ev) => setE2ee(ev.target.checked)}
    //       ></input>
    //       <label htmlFor="use-e2ee">Enable end-to-end encryption</label>
    //     </div>
    //     {e2ee && (
    //       <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
    //         <label htmlFor="passphrase">Passphrase</label>
    //         <input
    //           id="passphrase"
    //           type="password"
    //           value={sharedPassphrase}
    //           onChange={(ev) => setSharedPassphrase(ev.target.value)}
    //         />
    //       </div>
    //     )}
    //   </div>

    //   <hr
    //     style={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.15)', marginBlock: '1rem' }}
    //   />
    //   <button
    //     style={{ paddingInline: '1.25rem', width: '100%' }}
    //     className="lk-button"
    //     type="submit"
    //   >
    //     Connect
    //   </button> */}
    // </form>
  );
}

export const getServerSideProps: GetServerSideProps<{ tabIndex: number }> = async ({
  query,
  res,
}) => {
  res.setHeader('Cache-Control', 'public, max-age=7200');
  const tabIndex = query.tab === 'custom' ? 1 : 0;
  return { props: { tabIndex } };
};

const Home = ({ tabIndex }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();
  function onTabSelected(index: number) {
    const tab = index === 1 ? 'custom' : 'demo';
    router.push({ query: { tab } });
  }
  return (
    <>
      <main className={styles.main} data-lk-theme="default">
        <div className="header">
          <img src="/images/sea-logo-big.png" alt="Logo" />
        </div>

        <Tabs selectedIndex={tabIndex} onTabSelected={onTabSelected}>
          <DemoMeetingTab label="Meeting" />
          <CustomConnectionTab label="Info" />
        </Tabs>
      </main>
      <footer data-lk-theme="default">
        Integrated by &nbsp;
        <a href="https://www.smartelephantapps.com" target='_blank'>
          Smart Elephant Apps
        </a>
      </footer>
    </>
  );
};

export default Home;
